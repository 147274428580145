<template>
  <v-card elevation="2"
          outlined
          class="d-flex flex-column"
          @click="show = !show" 
  >
    <v-card-title>{{ title }}</v-card-title>
      <v-card-actions>
     
      <v-btn
        icon
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

         <div class="d-flex flex-column ">
                 <v-img :src="img_link" class="v-img"/>

      <v-card-text>{{ text }}</v-card-text>
    </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
export default {
  name: 'NewsItem',
  props: {
    title: String,
    text: String,
    img_link: String,
    date: String
  },
  data: () => ({
      show: false,
  })

}
</script>
<style scoped>
.v-img{
  max-width: 100%;
}
</style>
