<template>
  <v-app>
    <v-app-bar class="primary header" :prominent="prominent" :dense="!prominent" style="border-radius: 0 0 20px 20px;" app>
      <div class="d-flex justify-center align-center" v-bind:class="isLandscape" style="height: 100%; width: 100%;">

        <v-img
            alt="Cozmix Logo"
            contain
            :src="logoSrc"
            height="60%"
            max-width="100"
            v-on:click="processNavigationClick('home')"
        />
        <PlanetBar :key="'PlanetBar-'+currentLanguageCode+'-'+orbitalData.length" v-on:NavigationClick="processNavigationClick"
                   iconHeight="30"
                   iconWidth="45"
                   height="100%"
                   width="1"
                   :overview="orbitalData"
                   class="planetbar"
        />
      </div>
    </v-app-bar>

    <v-main d-flex flex-column align-content-center class="black">
      <component :is="currentMainComponent" :key="currentLanguageCode-planetKey" :planetName="planetKey" :languageCode="currentLanguageCode" :overview="orbitalData"
                 v-on:NavigationClick="processNavigationClick" />
    </v-main>

    <v-footer app class="primary stars-bg d-flex justify-space-around align-center flex-row" style="border-radius: 20px 20px 0 0">
      <LanguageSelect v-on:LanguageSelect="updateCurrentLanguageCode" :languages="this.languages" :defaultLanguageCode="this.defaultLanguageCode" :key="languages.length"/>
      <NavigationButton v-for="btn in navButtons" :nav-object="btn" :key="btn.key" v-on:NavigationClick="processNavigationClick"/>
    </v-footer>
  </v-app>
</template>

<script>

import PlanetBar from '@/components/header/PlanetBar';
import ForestMap from '@/components/main/ForestMap';
import InfoPage from '@/components/main/InfoPage';
import DetailPage from '@/components/main/details/DetailPage';
import NewsPage from '@/components/main/news/NewsPage';
import LanguageSelect from '@/components/footer/LanguageSelect';
import NavigationButton from "@/components/footer/NavigationButton";
import FetchComponent from "@/components/util/FetchComponent";
import * as util from "@/assets/util.js";

export default {
  name: 'App',
  components: {
    FetchComponent,
    PlanetBar,
    ForestMap,
    DetailPage,
    NewsPage,
    InfoPage,
    NavigationButton,
    LanguageSelect
  },
  data() {
    return {
      logoSrc: require("./assets/Cozmix_logo.png"),
      currentMainComponent: ForestMap,
      currentLanguageCode: "",
      defaultLanguageCode: "nl",
      orbitalData: [],
      prominent:true,
      dense:false,
      planetKey: "",
      navButtons: Object,
      isLandscape: {
        'landscape': false,
        'portrait': true
      },
      languages: []
    }
  },
  methods: {
    processNavigationClick: function (name) {
      name = name.toLowerCase().trim();
      if (this.orbitalData.find(o => o.key === name)) {
        this.planetKey = name;
        name = "planet";
      }

      switch (name) {
        case "home":
          this.currentMainComponent = ForestMap;
          break;
        case "news":
          this.currentMainComponent = NewsPage;
          break;
        case "info":
          this.currentMainComponent = InfoPage;
          break;
        case "planet":
          this.currentMainComponent = DetailPage;
          break;
        default:
          alert("Not implemented yet.");
      }
      this.footerTextSwap(this.currentLanguageCode);
    },
    updateCurrentLanguageCode: async function (code) {
      if(code!==this.currentLanguageCode){
        this.currentLanguageCode = code;
        this.footerTextSwap(code);
      }
    },
    footerTextSwap: function (code) {
      let navInfo = {
        info: {
          key: "info",
          text: util.getTextByKey("info", code)
        },
        news: {
          key: "news",
          text: util.getTextByKey("news", code)
        },
        home: {
          key: "home",
          text: "Home"
        }
      };

      function reloadInfo() {
        Object.keys(navInfo).forEach((k) => {
          let obj = navInfo[k];
          if(obj.key !== "home") {
            obj.text = util.getTextByKey(obj.key, code);
          }
        });
      }

      let nav = [];
      let main = this.currentMainComponent;
      reloadInfo();
      switch (main) {
        case ForestMap:
          nav.push(navInfo.info);
          nav.push(navInfo.news);
          break;
        case DetailPage:
          nav.push(navInfo.home);
          break;
        case InfoPage:
          nav.push(navInfo.home);
          nav.push(navInfo.news);
          break;
        case NewsPage:
          nav.push(navInfo.home);
          nav.push(navInfo.info);
          break;
      }
      this.navButtons = nav;
    },
    handleOrientationChange() {
      const o9n = require('o9n');
      const orientation = o9n.orientation.type;
      if (orientation === "portrait-primary") {
        this.prominent=true;
        this.isLandscape = {
          'portrait': true,
          'landscape': false
        }
      } else if (orientation === "landscape-primary" && Math.min(window.screen.width,window.screen.height) < 768) {
        this.prominent=false;
        this.isLandscape = {
          'portrait': false,
          'landscape': true
        }
    }
  },
    initLanguage: async function () {
      this.languages = await util.updateLanguage(this.currentLanguageCode);
      await util.updateLocalstorage(this.languages);
      this.orbitalData = util.getOverviewFromLS(this.currentLanguageCode);
      if(!this.orbitalData) {
        await util.updateOverview(this.currentLanguageCode);
        await this.initLanguage();
      }
      this.footerTextSwap(this.currentLanguageCode);
    }},
  beforeCreate() {
    this.$vuetify.theme.dark = true;
    util.installSW();
  },
  mounted() {
    this.currentLanguageCode = this.defaultLanguageCode;
    this.handleOrientationChange();
    this.initLanguage();
    window.addEventListener(
        "orientationchange",
        this.handleOrientationChange
    );
  }
}
</script>

<style scoped>
.header {
  background-image: url("assets/header_bg.jpg") ;
  background-repeat: repeat;
  background-color: #cccccc;
}

.landscape {
      flex-direction: row;
      height:20px;
}
.portrait {
      flex-direction: column;
      height:100%;
}

</style>

<style>
.v-card {
  margin-top: 1em;
}
</style>


