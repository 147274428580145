<template>
  <v-slide-group :height="height" :width="width" show-arrows>
    <v-slide-item v-for="planet in planets" :key="planet.name">
      <v-img contain :id="planet.key" :title="planet.name" :alt="planet.name" :src="planet.img" :width="iconWidth" :height="iconHeight"
             transition="scale-transition" v-on:click="planetClick" active-class='white--text'/>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  name: "PlanetBar",
  props: {
    width: String,
    height: String,
    iconHeight: String,
    iconWidth: String,
    overview: Array
  },
  data: function() {
    return {
      planets: []
    }
  },
  methods: {
    planetClick: function (e) {
      let name = e.target.parentElement.attributes.getNamedItem("id").value;
      this.$emit("NavigationClick", name.toLowerCase());
    },
    createPlanetsArray: function(overview) {
      let ordered = [overview.length];
      overview.forEach(planet => {
        let idx = (planet.position) ? planet.position + 1 : 0;
        idx = (planet.key === "mercury") ? 1 : idx;
        ordered[idx] = planet;
      });

      let planets = [];
      ordered.forEach( (o) => {
              let p = {
                key: o.key,
                name: (o.name) ? o.name : o.key,
                img: require(`../../assets/planets/${o.key}.svg`)
              };
              planets.push(p);
      });
      this.planets = planets;
    }
  },
  mounted() {
    if(this.overview && this.overview.length > 0) this.createPlanetsArray(this.overview);
  }
}
</script>

<style scoped>

</style>