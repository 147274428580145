<template>
<v-container class="v-flex v-col">
    <v-card class="card">
        <v-card-title>{{infoData.introduction_title}}</v-card-title>
        <v-card-text>
            {{infoData.introduction_text}}
        </v-card-text>
    </v-card>
    <v-card class="card">
        <v-card-title>{{infoData.map_title}}</v-card-title>
        <v-img src="../../assets/planetenpad.png"></v-img>
        
    </v-card>
    <v-card class="card">
        <v-card-title>{{infoData.solar_system_title}}</v-card-title>
        <v-card-text>
           {{infoData.solar_system_text}}
        </v-card-text>
    </v-card >
        <v-card class="card">
        <v-card-title>{{infoData.artist_title}}</v-card-title>
        <v-card-text>
           {{infoData.artist_text}}
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>

import * as util from "@/assets/util.js";

export default {
   name: "InfoPage",
    props: {
        languageCode: String
    },
    data: () => ({
        infoData:[]
    }),
    beforeMount() {
            this.infoData = util.getInformationPageFromLS(this.languageCode);
        }
}
</script>

<style scoped>
    .card{
        margin-bottom: 10px;
    }
</style>