<template>
    <v-container class="d-flex flex-column justify-lg-space-between">
        <v-card app class="d-flex">
            <v-img v-if="details.image_url" :src="details.image_url" max-width="40%"/>
            <div>
                <v-card-title class="text-capitalize">{{ (details.name) ? details.name : details.key }}</v-card-title>
                <v-card-subtitle v-if="details.dwarf">{{ findLocalisationString('dwarf') }}</v-card-subtitle>
                <v-divider></v-divider>
                <v-list-item-group>
                    <v-list-item v-for="d in primaryDetails" :key="d">
                        <v-list-item-content :class="{hidden: findUnitByKey(d) === null}">
                            <v-list-item-title>
                              <strong>{{ findUnitByKey(d, 'label') }}:</strong>
                              {{ details[d] }} {{findUnitByKey(d, 'unit') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </div>
        </v-card>
        <DescriptionCard :title="findLocalisationString('description')" :description="details.description"/>
        <GameCard v-if="details.game != null" :description="details.game" />
        <MythCard v-if="details['mythological_figures']" :title="findLocalisationString('mythology')" :description="details.mythological_figures[0].description"
                  :subtitle="details.mythological_figures[0].title"
                  :imgMyth="details.mythological_figures[0].image_url"
                  :learn_more="findLocalisationString('learn_more')"
        />
        <ScienceCard v-if="planet" :details="details" :keys="scienceTime" :units="units"
                     :title="findLocalisationString('properties')"/>
        <ScienceCard v-if="planet" :details="details" :keys="scienceOther" :units="units"
                     :title="findLocalisationString('properties_others')"/>
      <MoonsCard v-if="!star && details['orbitals'] && details.orbitals.length>0" :moonsTag="findLocalisationString('moon_count')" :moons="moons"/>
      <WeightWidget v-if="!star" :gravity="parseFloat(details.grav_acc)" :planet-name="details.name" :name-label="findLocalisationString('name')" :earth-name="findLocalisationString('earth')"
                      :weight-label="findLocalisationString('weight')" :submitText="findLocalisationString('send')" people-key="people"></WeightWidget>
    </v-container>
</template>

<script>
  import MythCard from "@/components/main/details/MythCard";
  import DescriptionCard from "@/components/main/details/DescriptionCard";
  import ScienceCard from "@/components/main/details/ScienceCard";
  import WeightWidget from "@/components/extras/WeightWidget";
  import * as util from "@/assets/util.js";
  import GameCard from "@/components/main/details/GameCard";
  import MoonsCard from "@/components/main/details/MoonsCard";


    export default {
        name: 'DetailPage',
        components: {
            GameCard,
            MythCard,
            DescriptionCard,
            ScienceCard,
            WeightWidget,
            MoonsCard
        },
        props: {
            languageCode: String,
            planetName: String
        },
        data: () => ({
            details: Object,
            planet: true,
            star: false,
            simpleTemperature: true,
            units: [],
            moons: [],
            primaryDetails: ["diameter", "day_length", "temp_min", "temp_max", "moon_count"],
            scienceTime: ["orbital_time", "day_length", "orbital_distance", "plane_time"],
            scienceOther: ["diameter", "density", "mass_x_earth", "temp_avg", "atmosphere"]
        }),
        methods: {
            calcPlaneTime(x) {
                let distance = x * 1000000; // km
                let speed = 800; // km / h
                let time = distance / speed; // time in h
                return (time / 24 / 365.25).toFixed(2); // time in years
            },
            calcAverageTemp(min, max) {
                return (min && max) ? ((Number(min) + Number(max)) / 2).toFixed(2) : null;
            },
            initDetails: function () {
                this.star = this.planetName === "sun";
                this.planet = !(this.star || this.planetName === "ice dwarfs");
                if (this.star) this.primaryDetails = ["diameter_x_earth", "mass_x_earth", "temp_surface", "temp_core", "composition"];
                else if (!this.planet) this.primaryDetails = ["orbital_distance", "orbital_time", "plane_time"];
                this.details["plane_time"] = this.calcPlaneTime(this.details["orbital_distance"]);
                if (this.details["temp_min"] !== this.details["temp_max"]) {
                    this.simpleTemperature = false;
                    this.details["temp_avg"] = this.calcAverageTemp(this.details["temp_min"], this.details["temp_max"]);
                }
                if(this.planet) this.trimPrimaryDetails();
            },
            findUnitByKey: function (key, value="") {
                let res = this.units.find(o => o.key === key);
                if(!res) return key;
                return (value === "") ? res : res[value];
            },
            findLocalisationString: function (key) {
              return this.findUnitByKey(key, 'label');
            },
            trimPrimaryDetails: function() {
              let copy = this.primaryDetails.slice();
              let remove = [];

              copy.forEach((key) => {
                if(!this.details[key]) {
                  remove.push(key);
                }
              });

              if(this.simpleTemperature) {
                if(remove.indexOf('temp_max') === -1 || remove.indexOf('temp_min') === -1) {
                  if(this.details['temp_max']) this.details['temp'] = this.details['temp_max'];
                  else this.details['temp'] = this.details['temp_min'];
                  copy.push('temp');
                }
                if(remove.indexOf('temp_max') === -1) remove.push('temp_max');
                if(remove.indexOf('temp_min') === -1) remove.push('temp_min');
              }

              util.removeKeys(copy, remove);
              this.primaryDetails = copy;
            }
        },
        mounted() {
            this.details = util.getPlanetFromLS(this.planetName, this.languageCode);
            this.units = util.getUnits(this.languageCode);
            this.initDetails();
            if (this.planet && !this.details.dwarf) this.moons = util.getMoonsFromLS(this.languageCode, this.planetName, this.details.orbitals);
        }
    }
</script>

<style scoped>

</style>