<template>
  <div id="newsfeed">
    <NewsItem v-for="item in news_data" :key="item.id" :title="item.title" :text="item.text" :img_link="item.image_url"/>

    <FetchComponent 
      :endpoint="endpoint"
      :language-code="languageCode"
      v-on:FetchCompleted="processFetch"
    ></FetchComponent>
  </div>
</template>

<script>
import NewsItem from "@/components/main/news/NewsItem";
import FetchComponent from "@/components/util/FetchComponent";

export default {
  name: "NewsPage",
  components: {
    NewsItem,
    FetchComponent,
  },
  props: {
    languageCode: String,
  },
  data: function () {
    return {
      endpoint: "/news",
      news_data: [],
    };
  },
  methods: {
    processFetch: function (json) {
      this.news_data = json.data;
    },
  },
};
</script>
