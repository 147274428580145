<template>
  <v-container class="flex align-center justify-center" v-if="loading">
    <v-progress-circular color="accent" indeterminate value="30"></v-progress-circular>
  </v-container>
</template>

<script>
import * as util from "@/assets/util.js";

  export default {
  name: "FetchComponent",
  props: {
    endpoint: String,
    languageCode: String,
    url: String
  },
  data: function () {
    return {
      baseUrl: "",
      loading: true
    }
  },
  methods: {
    createUrl: function () {
      let end = (this.endpoint[0] === "/") ? this.endpoint.substr(1, this.endpoint.length - 1) : this.endpoint;

      if(this.languageCode.length > 2) {
        throw("Language Code is too long, only 2 characters allowed");
      }

      let url = "";
      url = url.concat(this.baseUrl, this.languageCode, "/", end);
      return url;
    },
    fetchApi: function () {
      let url = (this.url) ? this.url : this.createUrl();
      console.log("url: ", url);
      fetch(url)
          .then((result) => {
            if(result.status < 400) return result.json();
            throw "FetchFailed";
          })
          .then((json) => {
            this.$emit("FetchCompleted", json)
            this.loading = false;
          }).catch( (e) => {
            this.$emit("FetchFailed", e);
            this.loading = true;
          });
    }
  },
  beforeMount() {
    this.baseUrl = util.getBaseUrl();
  },
  mounted() {
    this.fetchApi();
  }
}
</script>
