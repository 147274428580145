<template>
  <v-menu open-on-click top attach="">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-bind="attrs" v-on="on" color="secondary" rounded>
        <img :src="selectedLanguage.icon" :alt="selectedLanguage.name" :title="selectedLanguage.name" width="30px">
      </v-btn>
    </template>
    <v-list width="150" class="force-front" color="secondary">
      <v-list-item v-for="lang in languages" :key="lang.code" :value="lang.code" v-on:click="languageClicked" class="flex flex-row justify-lg-space-between">
        <v-list-item-title class="black--text text-capitalize">{{lang.name}}</v-list-item-title>
        <img :src="lang.icon" :alt="lang.name" :title="lang.name" width="30px">
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
  name: "LanguageSelect",
  data: function () {
    return {
      selectedLanguage: {name: "", code:""},
    }
  },
  props: {
    defaultLanguageCode: String,
    languages: Array
  },
  methods: {
    languageClicked: function (e) {
      let lang = this.findLanguageByName(e.target.parentElement.firstChild.textContent);
      this.selectedLanguage = lang;
      this.$emit("LanguageSelect", lang.code);
    },
    findLanguageByName(name) {
      return this.filterLanguageByProperty('name', name);
    },
    findLanguageByCode(code) {
      return this.filterLanguageByProperty('code', code);
    },
    filterLanguageByProperty(property, value) {
      return this.languages.find((lang) => {
        if (lang[property] === value) {
          return lang;
        }
      });
    }
  },
  mounted() {
    this.selectedLanguage = this.findLanguageByCode(this.defaultLanguageCode);
  }
}
</script>

<style scoped>
.force-front {
  z-index: 1;
  position: relative;
}
</style>