<template>
    <v-card :class="{lightCard: light}"  >
    <div  class="d-flex flex-row justify-space-between">
        <v-card-title>{{ name }}</v-card-title>
        <v-card-actions>
            <v-btn icon @click="show = !show">
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions>
    </div>
        <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>
                <v-img :src="image_url" max-height="250" contain/>
                <v-card-text>{{ description }}</v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    name:'MoonCard',
    props: {
        name: String,
        description: String,
        image_url: String,
        light: Boolean
    },
    data: () => ({
        show:false,
    })
}
</script>

<style scoped>
.lightCard {
  background-color: #424242;
}
</style>