<template>

  <v-card app  @click="show = !show">
    <div class="d-flex column justify-space-between">

    <v-card-title>
      {{ (title) ? title : fbTitle }}
    </v-card-title>

    <v-card-actions>
     
      <v-btn
        icon
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-gamepad-variant-outline' }}</v-icon>
      </v-btn>
    </v-card-actions>
    </div>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          {{ description }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name:'GameCard',

  props: {
    title: String,
    description: String,
  },
  data: () => ({
      show: false,
      fbTitle: "Game",
  })
}

</script>