<template>
  <div id="map-forest">
  </div>
</template>

<script>
import * as util from "@/assets/util";
import * as L from 'leaflet/dist/leaflet';
import '../../plugins/leaflet-grayscale-master/TileLayer.Grayscale';

export default {
  name: "ForestMap",
  data: function () {
    return {
      forestMap: Object,
      statues: null,
      statuesFB: [
        {
          name: "Sun",
          key: "sun",
          coords: [51.17666, 3.15332],
        },
        {
          name: "Mercury",
          key: "mercury",
          coords: [51.17662, 3.15337],
        },
        {
          name: "Venus",
          key: "venus",
          coords: [51.17659, 3.15342],
        },
        {
          name: "Earth",
          key: "earth",
          coords: [51.17656, 3.15348],
        },
        {
          name: "Mars",
          key: "mars",
          coords: [51.17653, 3.15357],
        },
        {
          name: "Ceres",
          key: "ceres",
          coords: [51.17649, 3.15396],
        },
        {
          name: "Jupiter",
          key: "jupiter",
          coords: [51.17586, 3.15523],
        },
        {
          name: "Saturn",
          key: "saturn",
          coords: [51.17505, 3.15552],
        },
        {
          name: "Uranus",
          key: "uranus",
          coords: [51.17446, 3.15572],
        },
        {
          name: "Neptune",
          key: "neptune",
          coords: [51.17330, 3.15671],
        },
        {
          name: "Pluto",
          key: "pluto",
          coords: [51.17204, 3.15808],
        },
        {
          name: "Ice Dwarfs",
          key: "ice dwarfs",
          coords: [51.17055, 3.15954]
        }
      ]
    };
  },
  props: {
    languageCode: String
  },
  methods: {
    markerClick(e) {
      let key = e.target.options.alt;
      this.$emit("NavigationClick", key);
    },
    setStatues() {
      let overview = util.getOverviewFromLS(this.languageCode);
      if(overview && overview.length > 0) {
      const planetMarkers = [];
      overview.forEach((planet) => {
        planetMarkers.push({
          name: (planet.name) ? planet.name : planet.key,
          key: planet.key,
          coords: [planet.lat, planet.long]
        });
      });
      this.statues = planetMarkers;
      }
      else {
        this.statues = this.statuesFB;
      }
      this.createForestMap();
    },
    createForestMap: function () {
      let forestMap = L.map("map-forest");
      let latitude = 51.17631;
      let longitude = 3.15450;
      let zoom = 20;
      forestMap.setView([latitude, longitude], zoom);
      L.tileLayer.grayscale('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}',
          {
            foo: 'bar',
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
            fadeAnimation: false
          }).addTo(forestMap);
      forestMap.setMaxBounds([51.17761, 3.15136], [51.7, 3.16168]);
      forestMap.setMinZoom(17);

      this.statues.forEach((statue) => {
        let markerOptions = {
          "keyboard": true,
          "title": statue.name,
          "alt": statue.key
        }

        let marker = L.marker(statue.coords, markerOptions);
        marker.addEventListener("click", this.markerClick);
        marker.addTo(forestMap);
      });
      this.forestMap(forestMap);
    }
  },
  mounted: function () {
    this.setStatues();
  }
}
</script>

<style scoped>
#map-forest {
  height: 100%;
  z-index: 2;
  position: relative;
}
</style>