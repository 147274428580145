<template>
    <v-item-group>
        <v-card v-for="person in people" :key="person.id">
            <ul>
                <li>
                    {{nameLabel}}: {{person.name}}
                </li>
                <li>
                    {{weightLabel}} ({{ earthName }}): {{person.weight}} kg
                </li>
                <li>
                    {{weightLabel}} ({{planetName}}): {{calcPersonWeight(person)}} kg
                </li>
                <v-btn color="accent" outlined small v-on:click="removePerson(person.id)">X</v-btn>
            </ul>
        </v-card>
        <v-card>
            <v-form :submit="addPerson" ref="personForm">
                <v-text-field v-model="formName" :label="nameLabel" placeholder="John"></v-text-field>
                <v-text-field type="number" v-model="formWeight" :label="weightLabel" suffix="kg"
                              placeholder="80"></v-text-field>
                <v-btn v-on:click="addPerson" color="accent" outlined>{{submitText}}</v-btn>
            </v-form>
        </v-card>
    </v-item-group>
</template>

<script>
    export default {
        name: "WeightWidget",
        props: {
            "nameLabel": String,
            "weightLabel": String,
            "submitText": String,
            "earthName": String,
            "planetName": String,
            "gravity": Number,
            "peopleKey": String
        },
        data: function () {
            return {
                people: this.getPeople(),
                formName: "",
                formWeight: null
            }
        },
        methods: {
            getPeople: function () {
                let ls = JSON.parse(window.localStorage.getItem(this.peopleKey));
                return (ls) ? ls : [];
            },
            setPeople: function (people) {
                window.localStorage.setItem(this.peopleKey, JSON.stringify(people));
            },
            arrayContains: function (arr, item) {
                let max = arr.length;
                for (let i = 0; i < max; i++) {
                    if(arr[i].id === item.id) return true;
                }
                return false;
            },
            addPerson: function () {
                let n = String(this.formName);
                let w = String(this.formWeight);
                this.formName = "John";
                this.formWeight = 0;
                let person = {
                    name: n,
                    weight: w
                };

                let people = this.getPeople();
                if (!this.arrayContains(people, person)) {
                    person.id = people.length;
                    people[people.length] = person;
                    this.setPeople(people);
                    this.people = people;
                }
            },
            removePerson: function(id) {
                let people = this.getPeople();
                people = people.filter(x => x.id !== id);
                this.setPeople(people);
                this.people = people;
            },
            calcPersonWeight: function (person) {
                return this.calcWeight(person.weight, this.gravity);
            },
            calcWeight: function (weight, gravity) {
                const earth_grav = 9.81;
                return ((weight / earth_grav) * gravity).toFixed(2);
            }
        }
    }
</script>

<style scoped>

</style>