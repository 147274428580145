<template>
<v-card>
  <v-card-title>{{title}}</v-card-title>
  <v-card-text>{{description}}</v-card-text>
</v-card>
</template>

<script>
export default {
  name: "DescriptionCard",
  props: {
    title: String,
    description: String
  }
}
</script>

<style scoped>

</style>