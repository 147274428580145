<template>
  <v-btn
      color="secondary"
      elevation="5"
      rounded
      class="black--text"
      v-on:click="buttonClick"
  >
    {{navObject.text}}
  </v-btn>
</template>

<script>
export default {
  name: "NavigationButton",
  props: {
    navObject: Object
  },
  methods: {
    buttonClick: function () {
      let key = this.navObject.key;
      this.$emit("NavigationClick", key);
    }
  }
}
</script>

<style scoped>

</style>