<template>

  <v-card app>
    <v-img :src= "imgMyth" max-height="250" contain :alt="subtitle" :title="subtitle" />

    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-subtitle>
      {{subtitle}}
    </v-card-subtitle>

    <v-card-actions @click="show = !show">
      <v-btn
          class="text--white"
        text
      >
       {{ learn_more }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          {{ description }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name:'MythCard',

  props: {
    title: String,
    subtitle: String,
    description: String,
    learn_more: String,
    imgMyth: String,
  },
  data: () => ({
      show: false
  })
}

</script>