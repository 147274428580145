<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-list-item v-for="k in keysCopy" :key="k">
      <v-list-item-content>
        <v-list-item-title><strong>{{findUnitByKey(k, 'label')}}:</strong> {{ details[k] }} {{handleUnit(findUnitByKey(k, 'unit'))}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import * as util from "@/assets/util";

export default {
  name: "ScienceCard",
  props: {
    details: Object,
    keys: Array,
    units: Array,
    title: String
  },
  data: function () {
    return {
      keysCopy: this.keys.slice()
    }
  },
  methods: {
    findUnitByKey(key, value="") {
      let res = this.units.find(o => o.key === key);
      return (value === "") ? res : res[value];
    },
    handleUnit(unitNotation) {
      return (unitNotation) ? unitNotation : "";
    }
  },
  async mounted() {
    if (this.details && {}.toString.call(this.details) === '[object Function]') await this.details();
    let remove = [];
    this.keysCopy.forEach((key) => {
      if(!this.details[key]) {
        remove.push(key);
      }
    });
    util.removeKeys(this.keysCopy, remove);
  }
}
</script>
