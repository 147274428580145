<template>
    <div v-if="moons.length>1">
    <v-card>
        <v-card-title>{{moonsTag}}</v-card-title>
        <ul style="list-style: none">
            <li v-for="moon in moons" :key='moon.name'>
                <MoonCard :name="moon.name" :description="moon.description" :image_url="moon.image_url" :light="true"/>
            </li>
        </ul>
    </v-card>
    </div>
    <div v-else>
    <MoonCard :name="moons[0].name" :description="moons[0].description" :image_url="moons[0].image_url" :light="false"/>
    </div>
</template>

<script>
import MoonCard from "@/components/main/details/MoonCard";

export default {
    name: 'MoonsCard',
    components: {
        MoonCard
    },
    props: {
        moonsTag: String,
        moons: Array,
    },
}
</script>