const baseUrl = "https://planetenpad-admin.cozmix.cloud/api/";
let newVersion = false;

// Util Functions
function createInfoUrl(languageCode) {
    return baseUrl + languageCode + "/information";
}

async function isNewVersion() {
    if(!newVersion) {
        const oldCache = localStorage.getItem('cachenr'); // Get the old cacheversion
        const newCache = await fetchCacheVersionNumber(); // Get the new cacheversion
        if (oldCache !== newCache) {
            localStorage.setItem('cachenr', newCache);
            newVersion = true;
        }
    }
    return newVersion;
}

async function updateLocalstorage(languages) {
    languages.forEach((lang) => {
        updateLocalStorageByLanguageCode(lang.code);
    });
    return Promise.resolve();
}

async function updateLocalStorageByLanguageCode(code, versionCheck= true) {
    if (versionCheck && await isNewVersion()) {
        console.log("Getting new version for localstorage...");
        await fetchSolarSystem(code)
        await fetchUnits(code);
        await fetchInformationPage(code);
    } else console.log("Localstorage is up to date!");
}

async function updateOverview(code) {
    await updateLocalStorageByLanguageCode(code, false);
}

function removeKeys(arr, keys) {
    keys.forEach(key => removeKey(arr, key));
}

function removeKey(arr, key) {
    const idx = arr.indexOf(key);
    arr.splice(idx, 1);
}

// Local Storage Set
async function updateLanguage(languageCode) {
    function createLanguageArray(langs) {
        return langs.map((lang) => {
            let parts = lang.split(":");
            return {
                code: parts[0],
                name: parts[1],
                icon: require(`./languages/${parts[0]}.svg`)
            }
        });
    }

    let url = createInfoUrl(languageCode);

    if(await isNewVersion()) {
        let res = await fetch(url);
        let json = await res.json();
        let languageArray = createLanguageArray(json.languages);
        window.localStorage.setItem("languages", JSON.stringify(languageArray));
        window.localStorage.setItem(languageCode + "_info", JSON.stringify(json.units));
        return languageArray;
    }

    return getLanguages();
}

function saveInfoArray(info, langCode, prefix, property='key') {
    info.forEach((i) => {
        const name = (prefix) ? `${langCode}_${prefix}_${i[property]}` : `${langCode}_${i[property]}`;
        window.localStorage.setItem(name, JSON.stringify(i));

        if(i.orbitals && i.orbitals.length > 0) {
            saveInfoArray(i.orbitals, langCode, i.key, 'name')
        }
    });
}

// LocalStorage Get
function getLanguages() {
    return JSON.parse(window.localStorage.getItem("languages"));
}

function getUnits(languageCode) {
    let info = window.localStorage.getItem(languageCode + "_info");
    if (info !== "undefined") return JSON.parse(info);
    fetchUnits(languageCode);
    return getUnits(languageCode);
}

function getUnitTextByKey(key, code) {
    const units = getUnits(code);
    let found = false;
    let res = key;
    if(units) {
        for (let i = 0; i < units.length && !found; i++) {
            let unit = units[i];
            found = (unit.key === key);
            res = unit.label;
        }
    }
    return res;
}

function getPlanet(planet, languageCode) {
    return JSON.parse(window.localStorage.getItem(languageCode + "_" + planet));
}

function getMoons(languageCode, planet, moons) {
    let moonsData= [];
    for (let i = 0; i < moons.length; i++) {
        const moon = moons[i];
        moonsData.push(JSON.parse(window.localStorage.getItem(languageCode + '_' + planet + '_' + moon.name)));
    }
    return moonsData;
}

function getInformationPageFromLS(languageCode) {
    return JSON.parse(window.localStorage.getItem(languageCode +"_information"));
}

function getOverview(languageCode) {
    return JSON.parse(window.localStorage.getItem(languageCode + '_overview'));
}

// API Fetch
async function fetchApi(url) {
    let res = await fetch(url);
    if(res.status > 400 && res.status < 500) {
        return setTimeout(() => fetchApi(url), 10000);
    }
    else {
        return await res.json();
    }
}

async function fetchSolarSystem(code) {
    const url = baseUrl + code + "/star/1/orbital";
    const solarInfo = await fetchApi(url);
    window.localStorage.setItem(code + '_overview', JSON.stringify(solarInfo));
    saveInfoArray(solarInfo, code);
}

async function fetchInformationPage(langcode){
    const url = baseUrl + langcode + '/information';
    const data = await fetchApi(url);
    window.localStorage.setItem(langcode + "_information", JSON.stringify(data.language_fields[0]));
}

async function fetchUnits(code) {
    let url = createInfoUrl(code);
    const info = await fetchApi(url);
    window.localStorage.setItem(code + "_info", JSON.stringify(info.units));
}

async function fetchCacheVersionNumber() {
    let res = await fetchApi( baseUrl +"cache");
    return res.toString();
}

// Service Worker
function installSW() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register("../../service-worker.js").then(function (res) {
            console.log("Successfully registered service worker with scope:", res.scope);
        }).catch(function (err) {
            console.log("Error registering service worker:", err);
        })
    }
}

// Exports
exports.getBaseUrl = () => {
    return baseUrl
};
exports.removeKeys = removeKeys;
exports.getUnits = getUnits;
exports.getTextByKey = getUnitTextByKey;
exports.getLanguages = getLanguages;
exports.updateLanguage = updateLanguage;
exports.updateLocalstorage = updateLocalstorage;
exports.getPlanetFromLS = getPlanet;
exports.getMoonsFromLS = getMoons;
exports.getOverviewFromLS = getOverview;
exports.updateOverview = updateOverview;
exports.installSW = installSW;
exports.getInformationPageFromLS = getInformationPageFromLS;
